<template>
  <div :class="['toastification', variant]">
    <div class="d-flex align-items-start">
      <div class="col-icon">
        <IconSuccess v-if="variant == 'success'" />
        <IconError v-else-if="variant == 'danger'" />
        <IconAlertOutline v-else-if="icon === 'AlertOutline'" />
        <IconAlert v-else-if="variant == 'alert'" />
        <IconInfo v-else />
      </div>

      <div class="d-flex flex-grow-1 col-infos">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <IconClose />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import IconSuccess from '../../../assets/images/pages/check-circle-icon.svg';
  import IconError from '../../../assets/images/pages/cancel-circle-icon.svg';
  import IconInfo from '../../../assets/images/pages/info-circle-icon.svg';
  import IconAlert from '../../../assets/images/pages/alert-circle-icon.svg';
  import IconClose from '../../../assets/images/pages/close-button-icon.svg';
  import IconAlertOutline from '../../../assets/images/pages/alert-toastification-icon.svg';

  export default {
    components: {
      IconSuccess,
      IconError,
      IconInfo,
      IconAlert,
      IconClose,
      IconAlertOutline
    },
    props: {
      variant: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      hideClose: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toastification {
    padding: 16px;
    margin: 0;

    &.success {
      background-color: #4caf50;
    }
    &.danger {
      background-color: #f44336;
    }
    &.alert {
      background-color: #ff9800;
    }
    &.info {
      background-color: #2196f3;
    }
    .col-infos {
      margin-left: 5px;
      .toastification-title {
        color: #ffff;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
      }
      .text-body {
        color: #ffff !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .col-icon {
      svg {
        margin-top: 4px;
      }
    }
  }
</style>

<style lang="scss">
  .Vue-Toastification__toast {
    padding: 0 !important;
  }

  @media (max-width: 480px) {
    .Vue-Toastification__container {
      padding: 15px !important;
      align-items: end;
    }

    .Vue-Toastification__toast {
      min-width: 1px !important;
    }
  }
</style>
